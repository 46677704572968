import React from "react";
import Layout from "../../components/layout";

export default function Webinar() {
	return (
		<Layout>
			<div className="webinar">
				<iframe
					src="https://lu.ma/embed-checkout/evt-xXUtabfvKWHHva7"
					width="600"
					height="450"
					frameborder="0"
					style={{ border: "1px solid #bfcbda88", borderRadius: "4px" }}
					allowFullScreen=""
					aria-hidden="false"
					tabIndex="0"
				></iframe>
			</div>
		</Layout>
	);
}
